import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Link from '../utils/link'
import Seo from '../components/seo'
import ReadyToGetStarted from '../components/ready-to-get-started'

import RenderBlock from '../utils/render-block'

class GuideTemplate extends Component {

  openGuides(){
    document && document.querySelector('.header__nav').classList.add('guides-active')
  }

  render() {
    let page = this.props.data.wpPost

    return (
      <>
        <Seo
          bodyClass={page.slug}
          {...page.seo}
        />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/guides/'>Guides</Link></li>
              <li>{page.title}</li>
            </ul>
          </div>
        </section>
        <section className='page-title'>
          <div className='page-title__inner'>
            <h1>{ page.title }</h1>
            { page.acf.subtitle && <p className='page-title__subtitle'>{page.acf.subtitle}</p> }
          </div>
        </section>
        <div className='guide-blocks'>
          { page.acf.content && page.acf.content.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'post'))  }
        </div>
        { page.acf.hasReadyBlock &&
          <ReadyToGetStarted />
        }
      </>
    )
  }
}

export const guideQuery = graphql`
  query ($id: String!) {
    wpPost(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        schema {
          raw
        }
      }
      acf {
        subtitle
        hasReadyBlock
        content {
          ... on WpPost_Acf_Content_Text {
            fieldGroupName
            text
          }
          ... on WpPost_Acf_Content_Image {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 1200
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            caption
          }
        }
      }
    }
  }
`

export default GuideTemplate
